.jenkins-radio-help-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.jenkins-radio {
  margin-top: 3px;

  &:not(:last-of-type) {
    margin-bottom: calc(var(--section-padding) / 3);
  }

  &__input {
    position: absolute;
    opacity: 0;
    margin-top: 10px;

    &:hover {
      & + label {
        &::before {
          box-shadow: 0 0 0 10px transparent, inset 0 0 0 5px var(--input-border-hover);
        }
      }
    }

    &:focus,
    &:active {
      & + label {
        &::before {
          transition: box-shadow var(--elastic-transition);
          box-shadow: 0 0 0 5px var(--focus-input-glow), inset 0 0 0 8px var(--focus-input-border);
        }
      }
    }

    &:checked {
      & + label {
        &::before {
          box-shadow: 0 0 0 10px transparent, inset 0 0 0 8px var(--focus-input-border);
        }
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;

        &::before {
          opacity: 0.35;
        }
      }
    }
  }

  &__label {
    position: relative;
    top: -8px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0 0 5px 32px;
    cursor: pointer;
    font-weight: var(--form-label-font-weight);
    // remove 300ms pause on mobile
    touch-action: manipulation;

    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;

      width: 22px;
      height: 22px;

      border-radius: 50%;
      background: transparent;
      box-shadow: 0 0 0 10px transparent, inset 0 0 0 2px var(--input-border);

      transition: box-shadow var(--standard-transition);
    }
  }

  &__description {
    margin: 0 0 0 32px;
    color: var(--text-color-secondary);
    line-height: 1.66;
  }

  &__children {
    position: relative;
    margin-top: 10px;
    opacity: 0;
    padding-left: 32px;
    transition: var(--standard-transition);
    visibility: hidden;
    max-height: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 10px;
      bottom: 0;
      width: 2px;
      background: var(--input-border);
      border-radius: 2px;
      transition: var(--standard-transition);
    }

    &:focus-within {
      &::after {
        background: var(--focus-input-border);
      }
    }
  }

  &__input:checked + &__label + &__children {
    visibility: visible;
    opacity: 1;
    max-height: none;
  }
}
